// Order.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import API from './../../api';
import InputAutocomplete from '../helpers/InputAutocomplete/InputAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debug, formatDate, addBusinessDays, swalConfirm, isDev } from './../../utils';
import moment from 'moment';
import CartDetails from '../cart/CartDetails';
import UserSignInModal from '../user/UserSignInModal';
import OrderProductsList from './OrderProductsList';
import './order.css';
import cloneDeep from 'lodash/cloneDeep';
import { setCart } from '../../redux/cartSlice';
import { FaCheck } from "react-icons/fa6";
import AddressSelector from './../cities/AddressSelector';
import PartnerSelector from './../partners/PartnerSelector';
function Order() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prototypeArticle = { totalQuantity: 0, category: {}, products: [] };
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart);
  const [data, setData] = useState({ ...cart });
  // pocId0 : id de la collecte de retrait
  // pocId1 : id de la collecte de restitution
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const [catalog, setCatalog] = useState(null);
  const [pickups, setPickups] = useState(null);
  const [collectionPickups, setCollectionsPickups] = useState([]);
  const [restitutionPickups, setRestitutionPickups] = useState([]);
  const [cities, setCities] = useState([]);
  const [showProductsList, setShowProductsList] = useState();
  const [streets, setStreets] = useState([]);
  const [cityName, setCityName] = useState('');// value de l'input de l'InputAutocomplete
  const [streetName, setStreetName] = useState(''); // value de l'input de l'InputAutocomplete
  // const [isOrderValid, setIsOrderValid] = useState(false);
  const suggestCities = useRef(null);
  const inputStreetNumber = useRef(null);
  const suggestStreets = useRef(null);
  const inputAdditionalAddress = useRef(null);
  const [topCategoryId, setTopCatgoryId] = useState('');
  const [previousAddressData, setPreviousAddressData] = useState({ // pour les switch  addressType (address | partner)
    partId: '',
    areaId: '',
    citId: '',
    adrId: '',
    citDeliveryZone: '',
  });
  /** handle input change ***  */
  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    const payload = { [key]: value };
    if (key == 'pocId0') {
      payload.pocId1 = '';
      const pickup = pickups.find(poc => poc.pocId === value);
      const nearestRestitutionDateCopy = moment(addBusinessDays(new Date(pickup.pocDate), config.minimalGapBetweenCollectionAndRestitution)).format('YYYY-MM-DD');
      payload.nearestRestitutionDate = nearestRestitutionDateCopy;
      const avalaiblePickups = pickups.filter(poc => poc.pocDate >= nearestRestitutionDateCopy);
      setRestitutionPickups(avalaiblePickups)
      if (avalaiblePickups.length > 0) {
        payload.pocId1 = avalaiblePickups[0].pocId;
      } else {
        payload.pocId1 = '';
      }
    }
    if (['pocId0', 'pocId1'].indexOf(key) > -1) {
      console.log(data.citDeliveryZone, config.deliveryCosts[data.citDeliveryZone])
      console.log('Set Delivery Costs')
      payload.deliveryCostsCode = data.citDeliveryZone;
      payload.deliveryCosts = [
        config.deliveryCosts[data.citDeliveryZone],
        value != 'poc0000000000000' ? config.deliveryCosts[data.citDeliveryZone] : 0
      ];
    }
    setData({ ...data, ...payload });
  }
  useEffect(() => {
    if (window.location.hash === '#cart') {
      window.setTimeout(() => {
        const cartAnchor = document.getElementById("cart");
        if (cartAnchor) {
          const offsetTop = cartAnchor.offsetTop - 100;
          window.scrollTo(0, offsetTop);
        }
      }, 300);
    }
    if (!data.addressType) {
      setData({ ...data, addressType: 'address' });
    }
    API.get('/catalog').then(response => {
      setCatalog(response.data)
    });
    if (data.areaId) {
      get_area_pickups(data.areaId);
    }
  }, []);
  useEffect(() => {
    const payload = {}
    if (pickups) {
      if (data.pocId0 != '') {
        const index = pickups.findIndex(p => p.pocId == data.pocId0);
        if (index == -1) {
          payload.pocId0 = '';
          payload.pocId1 = '';
          payload.nearestRestitutionDate = null;
        } else {
          payload.nearestRestitutionDate = moment(addBusinessDays(new Date(pickups[index].pocDate), 4)).format('YYYY-MM-DD');
          const avalaiblePickups = pickups.filter(poc => poc.pocDate >= payload.nearestRestitutionDate);
          setRestitutionPickups(avalaiblePickups)
          if (data.pocId1 != '' && data.pocId1 != 'poc0000000000000' && avalaiblePickups.findIndex(p => p.pocId == data.pocId1) == -1) {
            payload.pocId1 = '';
          }
        }
      }
    }
    setData({ ...data, ...payload })
  }, [pickups]);
  const isOrderValid = () => {
    return cart.citId !== '' && cart.areaId !== '' && cart.pocId0 !== '' && cart.pocId1 !== '' && cart.itemsCounts?.all > 0;
  }
  /*** get pickups for selected city */
  const get_area_pickups = async (areaId) => {
    // if (areaId)
    try {
      const response = await API.get('/city/' + areaId + '/pickups_occurrences');
      setPickups(response.data);
      if (response.data.length > 0) {
        const max_date = moment().add(14, 'days').format("YYYY-MM-DD");
        setCollectionsPickups(response.data.filter(poc => poc.pocDate <= max_date));
      }
    } catch (e) {
      setPickups([]);
    }
  }
  const handlesAddArticles = () => { // A RENOMMER 
    setShowProductsList(true);
  }
  const handleSetArticlesDetails = (articles) => {
    const dataCopy = cloneDeep(data);
    articles.forEach(article => {
      if (article.quantity > 0) {
        const index = dataCopy.articles.findIndex(item => item.artId === article.artId);
        if (index !== -1) {
          dataCopy.articles[index] = article;
        } else {
          dataCopy.articles.push(article);
        }
      }
    });
    setData({ ...dataCopy });
    setShowProductsList(false);
  }
  /**   save order and goto next page */
  const handleSaveOrder = () => {
    dispatch(setCart(data));
    navigate('/order/confirm');
  }
  const handleCartUpdate = (articles) => {
    setData({ ...data, articles: articles.filter(a => a.quantity > 0) })
  };
  useEffect(() => {
    dispatch(setCart(data));
  }, [data]);
  const handleCitySelected = (city) => {
    setCityName(city ? city.citPublicName : '')
  }
  const handleAddressSelected = (address) => {
    const payload = {}
    payload.pocId0 = '';
    payload.pocId1 = '';
    payload.nearestRestitutionDate = null;
    payload.areaId = '';
    if (address !== null) {
      payload.citId = address ? address.adrCityId : '';
      payload.adrId = address ? address.adrId : '';
      payload.areaId = address ? address.adrAreaId : '';
      payload.citDeliveryZone = address ? address.citDeliveryZone : '';;
      setCityName(address ? address.citName : '');
    }
    if (payload.areaId != '') {
      get_area_pickups(payload.areaId);
    } else {
      setPickups(null);
    }
    setData({ ...data, ...payload });
  }
  const toggleAdressType = (event) => {
    // console.log(previousAddressData.citId)
    const currentValues = {
      partId: data.partId,
      areaId: data.areaId,
      citId: data.citId,
      adrId: data.adrId,
      citDeliveryZone: data.citDeliveryZone,
    }
    const { name, value } = event.target;
    let payload = { addressType: value, ...previousAddressData, pocId0: '', pocId1: '' } // 
    get_area_pickups(payload.areaId);
    setData({ ...data, ...payload });
    setPreviousAddressData({ ...currentValues });
    // console.log(previousAddressData.citId)
  }
  const onPartnerSelected = (partner) => {
    console.clear();
    if (partner) {
      setData({
        ...data,
        partId: partner.partId,
        areaId: partner.partAreaId,
        citId: partner.partCityId,
        adrId: partner.partAddressId,
        citDeliveryZone: partner.partDeliveryZone,
      });
      get_area_pickups(partner.partAreaId);
    } else {
      setData({
        ...data,
        partId: '',
        areaId: '',
        citId: '',
        adrId: '',
        citDeliveryZone: '',
      });
    }
  }
  return (
    <div id="order">
      <div className='container'>
        <div className="mb-5">
          <h2 className='text-start text-blue'>1° Je souhaite un retrait : </h2>
          <section style={{ border: '2px solid var(--blue)', padding: '15px', borderRadius: '15px', background: 'var(--blue-1)' }}>
            <p className='mb-2'>
              <label className=''>
                <input type="radio" value="address" name="addressType" onChange={toggleAdressType} checked={data.addressType == 'address'} />
                &nbsp;A mon adresse (domicile, travail, ...)
              </label>
            </p><p>
              <label className=''>
                <input type="radio" value="partner" name="addressType" onChange={toggleAdressType} checked={data.addressType == 'partner'} />
                &nbsp;L'adresse d'une entreprise partenaire dont je suis salarié ou un point relais
              </label>
            </p>
            <div className='row'>
              <div className='col-md-6 col-lg-4  '>
                {data.addressType == 'address' &&
                  <AddressSelector
                    onCitySelected={handleCitySelected}
                    onAddressSelected={handleAddressSelected}
                    selectedCityId={data.citId}
                    selectedAddressId={data.adrId}
                  />}
                {data.addressType == 'partner' &&
                  <PartnerSelector onSelected={onPartnerSelected} selectedId={data.partId} />
                }
              </div>
            </div>
            {(data && data.citId != '' && pickups) &&
              <div className='form-group'>
                <label className='form-group-label'>Indications complémentaires pour le chauffeur :</label>
                <input type="text" name="additionnalInformations" value={data.additionnalInformations} onChange={handleChange} className='form-control' />
              </div>
            }
          </section>
        </div>
        {(data && data.citId && data.citId != '' && pickups) &&
          <>
            {pickups.length > 0 ?
              <>
                {data && data.pocId0 != data.areaId != '' &&
                  <>
                    <section className=''>
                      <h2 className='text-start text-blue'>2° Nos prochaines tournées dans votre commune : </h2>
                      <h3 className='text-gray'>Je sélectionne la date du retrait  : </h3>
                      <div className='labels collecte'>
                        {collectionPickups.map((pickup, key) => (
                          <label key={key} className={`select text-center pt-3 ${data.pocId0 && data.pocId0 === pickup.pocId ? 'selected' : ''}`}>
                            <input type="radio" value={pickup.pocId} onChange={handleChange} name="pocId0" />
                            <div className=''>
                              <small>
                                <strong>{formatDate(pickup.pocDate, 'text_ds')}</strong>
                                <span>{pickup.pocSlotStart} - {pickup.pocSlotEnd}</span>
                              </small>
                            </div>
                            {data.pocId0 && data.pocId0 === pickup.pocId &&
                              <span className="check"><FaCheck size={40} color='black' /></span>
                            }
                          </label>
                        ))}
                      </div>
                      {data.nearestRestitutionDate &&
                        <div style={{ opacity: '1' }}>
                          <h3 className='text-gray'>Je sélectionne la date de restitution : </h3>
                          <div className='labels restitution'>
                            {restitutionPickups.length > 0 ? restitutionPickups.map((pickup, key) => (
                              <label key={key} className={`select text-center pt-3 ${data.pocId1 && data.pocId1 === pickup.pocId ? 'selected' : ''}`}>
                                <input type="radio" value={pickup.pocId} onChange={handleChange} name="pocId1" />
                                <div className=''>
                                  <small>
                                    <strong>{formatDate(pickup.pocDate, 'text_ds')}</strong>
                                    <span>{pickup.pocSlotStart} - {pickup.pocSlotEnd}</span>
                                  </small>
                                </div>
                                {data.pocId1 && data.pocId1 === pickup.pocId &&
                                  <span className="check"><FaCheck size={40} color='black' /></span>
                                }
                              </label>
                            ))
                              :
                              <p>Aucune tournée n'est à ce jour programmée pour le retour de votre commande. Veuillez réessayer ultérieurement ou nous contacter.</p>
                            }
                          </div>
                          <div className='form-group text-green'>
                            <input
                              type="radio"
                              value={'poc0000000000000'}
                              onChange={handleChange}
                              name="pocId1"
                              className='me-2'
                              checked={data.pocId1 === 'poc0000000000000'}
                            />
                            Je préfère un retrait à la repasserie à compter du <u>{formatDate(data.nearestRestitutionDate, 'textfull')}</u>
                          </div>
                        </div>
                      }
                    </section>
                  </>
                }
                <a id="cart" name="cart"></a>
                {data.pocId0 != '' && data.pocId1 != '' &&
                  <>
                    <h2 className='text-start text-bleu'>3° Je commande : </h2>
                    <section style0={{ border: '2px solid var(--green)', padding: '15px', borderRadius: '15px', background: 'var(--green-1)' }}>
                      {(data?.articles?.length > 0) &&
                        <CartDetails catalog={catalog} cart={cart} onUpdate={handleCartUpdate} />
                      }
                    </section >
                    {data?.articles?.length > 0 &&
                      <div className='border rounded py-2 px-2 mb-5'>
                        Montant estimé des frais de transport si vous n'êtes pas abonné : <strong>
                          <span>ALLER&nbsp;:&nbsp;{data.deliveryCosts[0]}&nbsp;EUR</span>&nbsp;/&nbsp;<span>RETOUR&nbsp;:&nbsp;{data.deliveryCosts[1]}&nbsp;EUR</span>
                        </strong>
                      </div>
                    }
                    <section className='buttons-section' >
                      <button className='btn btn-blue me-sm-3 my-2' onClick={handlesAddArticles} >Ajouter des articles</button>
                      {(data && isOrderValid()) &&
                        <button className='btn btn-outline-blue  mx-2  my-2' onClick={handleSaveOrder} >Poursuivre ma commande</button>
                      }
                    </section>
                  </>
                }
              </> :
              <div className='row'>
                <div className='col-md-6 col-lg-4 col-xl-3'>
                  <div className='alert alert-warning small py-1 py-3'>
                    Votre commune n'est actuellement pas desservie par notre service à domicile.
                  </div>
                </div>
              </div>
            }
          </>
        }
      </div >
      {
        showProductsList &&
        <OrderProductsList
          topCategoryId={topCategoryId}
          catalog={catalog}
          onHide={event => setShowProductsList(false)}
          onAdd={handleSetArticlesDetails}
        />
      }
    </div >
  );
}
export default Order;


// PartnerSelector.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debug, formatDate, formatPrice, download } from './../../utils'
import { BiShow, BiHide } from "react-icons/bi";
import { FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import API from './../../api';
const PartnerSelector = ({ selectedId, onSelected }) => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    get_partners();
  }, []);
  const get_partners = async () => {
    const response = await API.get('/partners');
    setPartners(response.data);
    console.log(response.data)
  };

  const handleSelect = (event) => {
    const { name, value } = event.target;
    // if (value != '') {
    onSelected(partners.find(p => p.partId == value));
    // }

  }
  return (
    <div>
      <label className='form-group-label'>
        Sélectionner l'entreprise ou le point relais :
      </label>
      <select
        className='form-control form-control-sm'
        name="partnerId"
        value={selectedId}
        onChange={handleSelect}
      >
        <option></option>
        {partners.map((partner, idx) => (
          <option key={idx} value={partner.partId}>{partner.partName} - {partner.citPublicName}</option>
        ))}
      </select>


    </div>
  )

}
export default PartnerSelector;